import { parseStringToNumber as clearNumberPrefixAndParse } from "@/helpers/transformation/number";

const calculateBalanceAndGst = (
  totalDeposit: number,
  lotPrice: number,
  paid: number
) => {
  const GST_DIVISOR = 11;
  const balance = totalDeposit >= paid ? totalDeposit - paid : 0;
  const gstAmount = lotPrice / GST_DIVISOR;

  return {
    balance,
    gstAmount,
  };
};

interface Form {
  depositDetails: {
    totalDeposit: number;
    paid: number;
    balance: number;
    gstAmount: number;
  };
}

export function useDocusignDepositDetails(
  form: Form,
  depositPercentage: number,
  lotPrice: number
): {
  onSelectdepositPercentage: (percentage: number) => void;
  onChangeTotalDepositChange: (e: InputEvent) => void;
  onChangeBalance: (event: InputEvent) => void;
  calculateDepositDetails: () => void;
} {
  const DEPOSIT_PERCENTAGE_DIVISOR = 100;

  const updateDepositDetails = (
    totalDeposit: number,
    balance: number,
    gstAmount: number,
    paid: number
  ) => {
    form.depositDetails.totalDeposit = totalDeposit;
    form.depositDetails.balance = balance;
    form.depositDetails.gstAmount = gstAmount;
    form.depositDetails.paid = paid;
  };

  const onChangeBalance = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement;
    const { totalDeposit } = form.depositDetails;
    const paid = clearNumberPrefixAndParse(value || 0);

    const { balance, gstAmount } = calculateBalanceAndGst(
      totalDeposit,
      lotPrice,
      paid
    );
    updateDepositDetails(totalDeposit, balance, gstAmount, paid);
  };

  const onChangeTotalDepositChange = (e: InputEvent) => {
    if (!form.depositDetails.totalDeposit) return;
    const { value } = e.target as HTMLInputElement;
    const totalDeposit = clearNumberPrefixAndParse(value || 0);
    const paid = form.depositDetails.paid;
    const { balance, gstAmount } = calculateBalanceAndGst(
      totalDeposit,
      lotPrice,
      paid
    );

    updateDepositDetails(totalDeposit, balance, gstAmount, paid);
  };

  const calculateDepositDetails = () => {
    const { paid, totalDeposit: actualDeposit } = form.depositDetails;

    if (actualDeposit === 0) return;

    const totalDeposit =
      actualDeposit * (depositPercentage / DEPOSIT_PERCENTAGE_DIVISOR);

    const { gstAmount, balance } = calculateBalanceAndGst(
      totalDeposit,
      lotPrice,
      paid
    );

    updateDepositDetails(totalDeposit, balance, gstAmount, paid);
  };

  const onSelectdepositPercentage = (percentage: number) => {
    const paid = clearNumberPrefixAndParse(form.depositDetails.paid);
    depositPercentage = percentage;
    const totalDeposit =
      lotPrice * (depositPercentage / DEPOSIT_PERCENTAGE_DIVISOR);

    const { balance, gstAmount } = calculateBalanceAndGst(
      totalDeposit,
      lotPrice,
      paid
    );
    updateDepositDetails(totalDeposit, balance, gstAmount, paid);
  };

  return {
    onChangeBalance,
    onChangeTotalDepositChange,
    calculateDepositDetails,
    onSelectdepositPercentage,
  };
}
