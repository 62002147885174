import { useToast } from "vue-toastification";

const toast = useToast();

export function copyToClipboard(
  text: string,
  toastText = "Data was copied to clipboard"
) {
  navigator.clipboard
    .writeText(text)
    .then(() => toast.success(toastText, { timeout: 1500 }));
}
