import { ref, onMounted, onUnmounted, watch } from "vue";
import { useToast } from "vue-toastification";
import store from "@/store";
import { DocusignUserData } from "@/types/DocusignUserData";

export function useDocusignAuth(): void {
  const userData = ref(store.state.docusign.userData);
  const { accessToken, expiresDate, isLogged } = userData.value;
  let intervalId: null | number = null;
  const toast = useToast();

  const checkSession = () => {
    if (!accessToken || !expiresDate || !isLogged) return;
    if (isTokenExpired()) {
      toast.error("Your session on Docusign has expired.");
      clearInterval(intervalId as number);
    }
    processSession(userData.value);
  };

  onMounted(() => {
    processSession(userData.value);
    intervalId = setInterval(checkSession, 1000);
  });

  onUnmounted(() => {
    if (intervalId) clearInterval(intervalId);
  });

  watch(
    () => store.state.docusign.userData as DocusignUserData,
    (value: DocusignUserData) => {
      const { accessToken, expiresDate } = userData.value;
      if (
        value?.accessToken === accessToken ||
        value?.expiresDate === expiresDate
      )
        return;
      userData.value = value;
      processSession(value);
    },
    { deep: true, immediate: true }
  );
}

const isTokenExpired = () => {
  const { accessToken, expiresDate } = store.state.docusign.userData;

  const currentDate = new Date();
  const tokenExpirationDate = new Date(expiresDate ?? "");

  return !accessToken || !expiresDate || tokenExpirationDate < currentDate;
};

const processSession = (userData: DocusignUserData) => {
  const { accessToken, expiresDate } = userData;
  if (!accessToken || !expiresDate || new Date(expiresDate) < new Date()) {
    store.commit("docusign/clearUserData");
    return;
  }

  handleSessionStatus(expiresDate, userData);
};

const handleSessionStatus = (
  expirationDate: string | Date | null,
  userData: DocusignUserData
) => {
  if (!expirationDate) {
    store.commit("docusign/clearUserData");
    return;
  }

  const expiration = new Date(expirationDate);
  const hasTokenExpired = expiration < new Date();
  const action = hasTokenExpired ? "clearUserData" : "setUserData";
  const data = hasTokenExpired ? {} : { ...userData, isLogged: true };

  store.commit(`docusign/${action}`, data);
};
